
import { computed, defineComponent, ref, watch } from 'vue'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import Header from './views/Header.vue'
import Footer from './views/Footer.vue'
import SideBar from '@/components/sideBar/index.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Layout',
  components: {
    Header,
    Footer,
    SideBar
  },
  setup() {
    const router = useRouter()
    const isEcharts = ref(false)
    const noHeaderFooter = computed(() => {
      return ['/login'].includes(router.currentRoute.value.path)
    })
    watch(
      () => router.currentRoute.value.path,
      (path) => {
        console.log(path, 'path')
        if (path == '/Echarts') {
          isEcharts.value = true
        } else {
          isEcharts.value = false
        }
      },
      {
        deep: true, // 是否采用深度监听
        immediate: true // 首次加载是否执行
      }
    )
    return {
      locale: zhCn,
      isEcharts,
      noHeaderFooter
    }
  }
})
