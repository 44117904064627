
import { defineComponent, computed, onMounted, onUnmounted, reactive, ref } from 'vue'
import { useRoute,useRouter } from 'vue-router'
import { Top } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus/lib/components'
import type { ElForm } from 'element-plus'
import qs from 'qs'

import httpRequest, { fzzykRequest } from '@/service'

export default defineComponent({
  components: {
    Top
  },
  setup() {
    const isLogin = ref(false)
    fzzykRequest.get({ url: 'loginCheckAlways200' }).then(res=>{
      isLogin.value = res.data.isLogin
    })
    interface IFeed {
      [key: string]: any
    }

    type FormInstance = InstanceType<typeof ElForm>
    const route = useRoute()
    const router = useRouter()
    const showEntry = ref(true)
    const expand = ref(true)
    const liData = reactive([
      ['我的收藏',0],
      ['浏览历史',1],
      // ['使用帮助',2],
      ['意见反馈',3],
      // ['关于我们',5],
    ])
    const ruleFormRef = ref<FormInstance>()
    const ruleForm = reactive<IFeed>({
      lawRegulName: '',
      abnormalCode: [],
      details: '',
      verifyCode:''
    })
    const rules = reactive({
      lawRegulName: [
        {
          required: true,
          message: '请输入法律名称',
          trigger: 'blur'
        }
      ],
      abnormalCode:[
        {
          type: 'array',
          required: true,
          message: '请至少选择一个错误类型',
          trigger: 'change'
        }
      ],
      details: [
        {
          required: true,
          message: '请输入详细信息',
          trigger: 'blur'
        }
      ],
      verifyCode:[
      {
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }
      ]
    })
    const jumpClick = (i: any) => {
      switch (i) {
        case '我的收藏': {
          if(route.path == '/MyCollect'){
            return
          }
          const { href } = router.resolve({
            path: '/MyCollect'
          })
          window.open(href, '_blank')
        }
          break
        case '浏览历史': {
          if(route.path == '/BrowsHistory'){
            return
          }
          const { href } = router.resolve({
            path: '/BrowsHistory'
          })
          window.open(href, '_blank')
        }
          break
        case '使用帮助':
          openPdf()
          break
        case '意见反馈':
          ruleForm.lawRegulName = document.getElementsByTagName('title')[0]?.innerText
          dialogVisible.value = true
          break
        case '关于我们':{
          if(route.path == '/AboutUs'){
            return
          }
          const { href } = router.resolve({
            path: '/AboutUs'
          })
          window.open(href, '_blank')
        }
          break
        case '我的反馈':
          const { href } = router.resolve({
            path: '/MyFeedback'
          })
          window.open(href, '_blank')
          break
      }
    }

    //回到顶端
    const backTopFlag = ref(false)
    const backTop = () => {
      document.documentElement.scrollTop = 0
    }
    const handleScroll = () => {
      if (document.documentElement.scrollTop > 20 && route.path != '/CXYY') {
        backTopFlag.value = true
      } else {
        backTopFlag.value = false
      }
    }
    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
    })
    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })
    //使用帮助
    const openPdf = () => {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.style.height = '0'
      a.href = '/法治智库-使用帮助.pdf'
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      setTimeout(() => {
        a.remove()
      }, 100)
    }
    // 刷新验证码
    const codePath = ref('')
    const verifyCodeKey = ref('123')
    refreshCode()
    function refreshCode() {
      fzzykRequest.get({
        url: 'refreshCaptcha',
        params: {
          verifyCodeKey: verifyCodeKey.value
        }
      }).then(res=>{
        const { captchaKey, base64Img } = res.data
        verifyCodeKey.value = captchaKey
        codePath.value = base64Img
      })
    }
    // 错误类型字典
    const queryName = computed(() => {
      if(route.name == 'DetailPage') {
        return 'ABNORMAL_TYPE02'
      }else if(route.name == 'DetailCasePage'){
        return 'ABNORMAL_TYPE03'
      }else if(route.name == 'CaseLibraryDetail'){
        return 'ABNORMAL_TYPE04'
      }else if(route.name == 'DetailCasePageV2'){
        return 'ABNORMAL_TYPE05'
      }
      return 'ABNORMAL_TYPE01'
    })
    const abnormalCodes = ref<any[]>([])
    httpRequest.post({
      url: 'listDictionaryBatch',
      params: {
        name:queryName.value
      },
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).then(res=>{
      abnormalCodes.value = res.data[queryName.value]
    })
    //意见反馈
    const submitClick = (formEl: FormInstance | undefined) => {
      if (!formEl) return
      formEl.validate((valid) => {
        if (valid) {
          const params:any = {
            ...ruleForm,
            suggestType:queryName.value.slice(-1)+'0',
            verifyCodeKey:verifyCodeKey.value
          }
          let str = ''
          params.abnormalCode.forEach(item=>{
            str += abnormalCodes.value.find(i=>i.dmcod == item).dmcpt
          })
          params.abnormalName = str
          params.abnormalCode = params.abnormalCode.join()
          fzzykRequest.post({
            url: 'feedBack',
            data: qs.stringify(params),
            headers:{
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }).then(res=>{
            dialogVisible.value = false
            ElMessage({
              message: '感谢您的宝贵意见',
              type: 'success'
            })
          }).finally(()=>{
            refreshCode()
            ruleForm.verifyCode = ''
          })
        }
      })
    }
    // 关闭弹窗
    const dialogVisible = ref(false)
    const closeDialog = () => {
      ruleFormRef.value?.resetFields()
    }
    // 输入建议
    function querySearchAsync(queryStr,cb){
      httpRequest.post({
        url:'searchListLawList',
        params: {
          currentPage: 1,
          pageSize: 5,
          sort: 0,
          categoryId: 1,
          rangeId: 0,
          fuzzyTitleId: 2,
          isSynonym: true,
          isResults: false,
          searchTitle: queryStr,
        }
      }).then(res=>{
        const list:any[] = []
        res.data.forEach((item)=>{
          list.push(...item.list.map(i=>{
            const value = i.name.replace(/<font color='red'>(.*?)<\/font>/g,'$1')
            return {
              label:i.name,
              value
            }
          }))
        })
        cb(list)
      }).catch(() => {
        cb([])
      })
    }
    const navs = computed(() => {
      switch (route.path) {
        case '/WLegislation':
          return [
            { title: '立法辅助', id: 'lifa', src: '/img/publicity-page/nav1.png' },
            { title: '文件管理', id: 'guifan', src: '/img/publicity-page/nav2.png' }
          ]
        case '/WEnforce':
          return [
            { title: '入企扫码', id: 'ruqi', src: '/img/publicity-page/nav3.png' },
            { title: '智能组卷', id: 'zujuan', src: '/img/publicity-page/nav4.png' }
          ]
        case '/WSupervision':
          return [
            { title: '事项管理', id: 'tuijian', src: '/img/publicity-page/nav5.png' },
            { title: '智能评查', id: 'pingcha', src: '/img/publicity-page/nav6.png' },
          ]
        case '/WReconsideration':
          return [
            { title: '一体机', id: 'yitiji', src: '/img/publicity-page/nav7.png' },
            { title: '智能会议', id: 'tingzheng', src: '/img/publicity-page/nav8.png' }
          ]
        default:
          return []
      }
    })
    function jumpMD(item?){
      if(item) open(location.origin + '/CXYY?id='+item.id)
      else open(location.origin + '/CXYY')
    }
    return {
      navs,
      showEntry,
      expand,
      jumpMD,
      isLogin,
      liData,
      dialogVisible,
      ruleForm,
      rules,
      ruleFormRef,
      jumpClick,
      backTop,
      submitClick,
      backTopFlag,
      closeDialog,
      handleScroll,
      openPdf,
      codePath,
      verifyCodeKey,
      refreshCode,
      abnormalCodes,
      querySearchAsync,
      REQUIRE_LOGIN:process.env.VUE_APP_REQUIRE_LOGIN
    }
  }
})
