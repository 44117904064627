import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-55765020"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-wrapper" }
const _hoisted_2 = { class: "common-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_side_bar = _resolveComponent("side-bar")!
  const _component_el_config_provider = _resolveComponent("el-config-provider")!

  return (_openBlock(), _createBlock(_component_el_config_provider, { locale: _ctx.locale }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.isEcharts && !_ctx.noHeaderFooter)
          ? (_openBlock(), _createBlock(_component_Header, { key: 0 }))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_view)
        ]),
        (!_ctx.isEcharts && !_ctx.noHeaderFooter)
          ? (_openBlock(), _createBlock(_component_Footer, { key: 1 }))
          : _createCommentVNode("v-if", true),
        (!_ctx.isEcharts && !_ctx.noHeaderFooter)
          ? (_openBlock(), _createBlock(_component_side_bar, { key: 2 }))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["locale"]))
}